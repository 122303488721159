import React from 'react'

const Services = () => {
  return (
    <div id='services' className='bg-[#f2ecfc] h-screen flex items-center'>
      <div className="container mx-auto xl:p-4">
        <section className="flex flex-wrap items-center justify-center p-4">
          <div className='w-full lg:w-1/2 p-4 space-y-4'>
            <h1 className='text-center text-[#8144E5]'><u><b>How We Work</b></u></h1>
            <p className='text-xl'>
              You create an ad on our portal, upload a banner image (what the customer will see), 
              and provide a url/link for your website to which the customer will be directed when they click or tap the banner
            </p>
            <p className='text-xl'>We have a revenue share agreement model such that the people who see our ads receive benefits like airtime and discounts</p>
          </div>
          <div className='w-full lg:w-1/2 p-4'>
            <img 
              width={800} height={800} 
              className='w-full h-full object-cover rounded-lg border border-[#f2ecfc] shadow-lg'
              src="https://static.vecteezy.com/system/resources/previews/005/868/381/non_2x/digital-marketing-modern-flat-concept-for-web-banner-design-ad-campaign-and-online-promotion-in-mobile-apps-advertising-content-in-social-networks-illustration-with-isolated-people-scene-free-vector.jpg" alt="" 
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default Services
