import React from 'react'

const About = () => {
  return (
    <div id='about' className='h-screen flex items-center'>
      <div className="container mx-auto xl:last:p-4">
        <div className="flex flex-wrap justify-center p-4">
          <div className='w-full lg:w-1/2 p-4'>
            <img width={800} height={800} src="https://static.vecteezy.com/system/resources/previews/007/819/465/original/advertising-or-ads-illustration-for-mobile-social-media-campaign-business-promotion-brand-and-digital-marketing-in-flat-cartoon-style-vector.jpg" alt="" />
          </div>
          <div className='w-full lg:w-1/2 p-4 space-y-4'>
            <h1 className='text-center text-[#8144E5]'><u><b>About Us</b></u></h1>
            <div className='space-y-6'>
              <p className='text-xl'>We have thousands of customers who have our app on their phones. Ads are shown to them on key triggers like when a call is ended or an SMS is received.</p>
              <p className='text-xl'>We have a revenue share agreement model such that the people who see our ads receive benefits like airtime and discounts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
